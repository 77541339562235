.tokenomics-container {
    text-align: center;
    background-color: #000;
    padding: 40px;
    color: #fff;
  }
  
  .tokenomics h3 {
    font-size: 32px;
    color: #fff;
    margin-bottom: 30px;
  }
  
  .tokenomics-details {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .tokenomics-item {
    width: 300px;
    padding: 20px;
    background-color: #000;
    box-shadow: 0 4px 8px rgba(212, 198, 7, 0.1);
    border-radius: 8px;
    transition: transform 0.3s;
  }
  
  .tokenomics-item h4 {
    font-size: 24px;
    color: #ef476f;
    margin-bottom: 15px;
  }
  
  .tokenomics-item p {
    font-size: 16px;
    color: #fff;
  }
  
  .tokenomics-item:hover {
    transform: translateY(-10px);
  }
  