.about-container {
    background-color: #000;
    padding: 40px;
    color: #FFF;
}

.about-text {
    text-align: center;
}

.about-text h3 {
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 800;
    font-family: "Manrope", sans-serif;
}

.about-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 250px;
}

.about-paletee {
    border-left: 4px solid #FF8BF3;
    margin-bottom: 40px;
}

.about-paletee:nth-child(3) {
    border-left: 4px solid #FFF;
}

.about-paletee p {
    margin-left: 30px;
}

.about-text p {
    margin-bottom: 20px;
}

.about-image img {
    width: 500px;
}

.about-info button {
    background: transparent;
    color: #FFFFFF;
    border: none;
    padding: 10px;
    border-radius: 20px;
}

.about-info button:hover {
    cursor: pointer;
}

@media(max-width: 500px) {
    .about-image img {
        width: 350px;
    }
    .about-details {
        gap: 50px;
    }
}

@media (max-width: 900px) {
    .about-text h3 {
        margin-top: 50px;
    }
}