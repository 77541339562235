.hero-container {
    background: url('../../assets/images/background.png');
    height: 750px;
    transition:50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-container .logo-menu {
    display: flex;
}

.logo {
    margin-left: 20%;
    margin-top: 20px;
    width: 40%;
}

.hero-container ul {
    display: flex;
    margin: 27px 0px 0px 20px;
    align-items: baseline
}

.hero-container ul li {
    color: #fff;
    list-style: none;
    margin: 20px 15px 0px 15px;
    transition: ease-in-out 0.3s;
}

.hero-container ul li:hover {
    cursor: pointer;
    color: #FF00E5;
}

.hero-container ul button {
    background: rgba(255, 255, 255, 0.08);
    height: 50px;
    font-size: 20px;
    border: none;
    border-radius: 30px;
}

.hero-container ul button:hover {
    cursor: pointer;
}

.hero-container .line {
    position: absolute;
    top: 90px;
    left: 100px;
}

.hero-container .hero-text {
    color: #FFFFFF;
    font-size: 80px;
    font-weight: 800;
    text-align: center;
    margin-top: 170px;
    font-family: "Manrope", sans-serif;
}

.hero-container p {
    font-family: "Manrope", sans-serif;
    text-align: center;
    color: #FFF;
    font-size: 24px;
    margin-top: 30px;
}

.hero-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 100px;
}

.hero-input {
    padding: 20px 200px 20px 10px;
    border: 2px solid #FFFFFF;
    border-radius: 30px;
    background: transparent;
    position: relative;
    color: #FFFFFF;
    font-family: "Manrope", sans-serif;
}

.hero-button {
    position: absolute;
    margin-left: 190px;
    background: transparent;
    color: #FFFFFF;
    border: none;
    padding: 10px;
    border-radius: 20px;
}

.hero-input:focus {
    outline: none;
}

.hero-button:hover {
    cursor: pointer;
}

.hero-input::placeholder {
    color: #FFFFFF;
}

.hero-input:focus {
    outline-color: #FFFFFF;
}

.menu-bar {
    color: #FFF;
    margin-top: 40px;
    font-size: 20px;
    display: none;
}

.menu-bar:hover {
    cursor: pointer;
}

.menu-drawer {
    background-color: #000 !important;
    color: #FFF !important;
}

.menu-drawer .cancelIcon {
    font-size: 20px;
}

.menu-drawer .cancelIcon:hover {
    cursor: pointer;
}

.menu-drawer ul li {
    list-style: none;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 20px;
}

.menu-drawer ul li:hover {
    cursor: pointer;
}

.menu-drawer ul button {
    background: #0E76FD;
    color: #FFFFFF;
    font-weight: 900;
    border: none;
    padding: 6px;
    border-radius: 20px;
} 


@media (max-width: 425px) {
    .hero-container .menu-list {
        display: none;
    }
    .hero-container .line {
        display: none;
    }
    .hero-container .hero-text {
        font-size: 30px;
        margin-top: 100px;
    }
    .logo {
        width: 80%;
    }
    .menu-bar {
        display: flex;
    }
}

@media (max-width: 600px) {
    .hero-container .menu-list {
        display: none;
    }
    .hero-container .line {
        display: none;
    }
    .hero-container .hero-text {
        font-size: 30px;
        margin-top: 100px;
    }
}

@media (max-width: 1200px) {
    .hero-container .line {
        display: none;
    }
}