.footer-container {
    background: #000;
    padding: 40px;
    color: #fff;
}

.social-text {
    display: flex;
}

.social-icons {
    margin-bottom: 20px;
}

.social-icons a {
    color: rgba(255, 255, 255, 0.4);
    margin-left: 20px;
    font-size: 25px;
}

.footer-text {
    font-size: 20px;
    font-family: "Manrope", sans-serif;
    margin-left: 49%;
}

.footer-details {
    display: flex;
    justify-content: center;
}

.footer-quote-location {
    width: 60%;
}

.footer-quote-location a {
    display: block;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.4);
    font-weight: 800;
    font-family: "Manrope", sans-serif;
    margin-top: 20px;
}

.footer-quote-location span {
    color: #fff;
}

.footer-form .input-email {
    width: 96%;
    margin-top: 40px;
}

.footer-form input {
    background: transparent;
    border: none;
    margin-top: 10px;
    color: #FFF;
    border-bottom: 1.5px #FFF solid;
    margin-right: 20px;
    width: 200px;
    padding: 10px;
}

.footer-form input:focus {
    outline: none;
}

.footer-form button {
    background: #0E76FD;
    border: none;
    padding: 6px 8px 6px 8px;
    font-weight: 800;
    border-radius: 20px;
    color: #FFF;
    margin-top: 40px;
}

.footer-form button:hover {
    cursor: pointer;
}

.footer-copyright {
    margin-top: 50px;
    text-align: center;
}

@media(max-width: 500px) {
    .social-text {
        flex-direction: column;
    }
    .footer-text {
        margin-left: 2%;
    }
    .social-icons {
        width: 100%;
    }
    .footer-details {
        flex-direction: column;
        align-items: center;
    }
    .footer-form input {
        width: 100%;
    }
    .footer-form .input-email {
        width: 100%;
    }
    .footer-quote-location {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}