.direction-container {
    background-color: #000;
    padding: 40px;
    color: #fff;
}

.direction-text {
    text-align: center;
}

.direction-text h3 {
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 800;
    font-family: "Manrope", sans-serif;
}

.direction-text p {
    font-family: "Manrope", sans-serif;
}

.direction-elements {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 60px;
}

.direction-card {
    text-align: center;
    color: #fff;
    margin-top: 30px;
    background: #000;
    padding: 30px;
    border: 1px rgba(255, 255, 255, 0.1) solid;
    border-radius: 16px;
}

.direction-card img {
    height: 100px;
    margin-bottom: 20px;
}

.direction-card h5 {
    margin-bottom: 20px;
}

.direction-card p {
    margin-bottom: 20px;
}

.direction-card a {
    color: #fff;
    text-decoration: none;
    transition: ease-in-out 0.5s;
}

.direction-card a:hover {
    color: #FF00E5;
}

.direction-card h5:hover {
    color: #FF00E5;
    scale: 1.3;
    transition: ease-in-out 0.5s;
}