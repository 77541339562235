.faq-container {
    text-align: center;
    padding: 50px;
    background-color: #000;
  }
  
  .faq-container h3 {
    font-size: 32px;
    color: #fff;
    margin-bottom: 30px;
  }
  
  .faq-items {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-item {
    padding: 20px;
    margin: 10px 0;
    background-color: #000;
    box-shadow: 0 4px 8px rgba(228, 212, 30, 0.71);
    border-radius: 8px;
    transition: background-color 0.3s;
  }
  
  .faq-item h4 {
    font-size: 20px;
    cursor: pointer;
    color: #118ab2;
    margin: 0;
  }
  
  .faq-item p {
    font-size: 16px;
    color: #fff;
    margin-top: 10px;
  }
  
  .faq-item:hover {
    background-color: #b0fb6e;
  }
  