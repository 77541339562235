/* 全局样式设置 */
body, .roadmap-container, .tokenomics-container, .faq-container {
    background-color: #000;
    color: #fff;
    font-family: "Manrope", sans-serif;
    padding: 40px;
  }
  
  /* 标题样式 */
  h3 {
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 800;
    text-align: center;
  }
  
  h4, h5 {
    margin-bottom: 20px;
    font-weight: 700;
    transition: ease-in-out 0.5s;
  }
  
  h4:hover, h5:hover {
    color: #FF00E5;
    scale: 1.1;
  }
  
  /* 文字样式 */
  p {
    margin-bottom: 20px;
  }
  
  /* 链接样式 */
  a {
    color: #fff;
    text-decoration: none;
    transition: ease-in-out 0.5s;
  }
  
  a:hover {
    color: #FF00E5;
  }
  
  /* 组件内部样式 */
  .component-card {
    text-align: center;
    color: #fff;
    margin-top: 30px;
    background: #000;
    padding: 30px;
    border: 1px rgba(255, 255, 255, 0.1) solid;
    border-radius: 16px;
    transition: ease-in-out 0.5s;
  }
  
  .component-card:hover {
    border-color: #FF00E5;
  }
  
  /* 各组件特定样式 */
  .roadmap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 60px;
  }
  
  .roadmap-phase {
    width: 300px;
    padding: 20px;
  }
  
  .tokenomics-details {
    display: flex;
    justify-content: center;
    gap: 60px;
    flex-wrap: wrap;
  }
  
  .faq-items {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-item {
    margin-top: 30px;
    padding: 20px;
    background-color: #000;
    border: 1px rgba(255, 255, 255, 0.1) solid;
    border-radius: 16px;
  }
  