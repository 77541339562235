.roadmap-container {
  text-align: center;
  padding: 50px;
  background-color: #000;
}

.roadmap h3 {
  font-size: 32px;
  color: #333;
  margin-bottom: 30px;
}

.roadmap {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.roadmap-phase {
  width: 300px;
  padding: 20px;
  margin: 10px;
  background-color: #edeaea;
  box-shadow: 0 4px 8px rgba(184, 181, 20, 0.1);
  border-radius: 8px;
  transition: transform 0.3s;
}

.roadmap-phase h4 {
  font-size: 24px;
  color: #0077b6;
  margin-bottom: 10px;
}

.roadmap-phase p {
  font-size: 16px;
  color: #444343;
}

.roadmap-phase:hover {
  transform: translateY(-10px);
  background-color: aquamarine;
  scale: 1.2;
}
